<script setup>
import { ref, computed } from "vue";
import ChipAppuntamento from "./ChipAppuntamento.vue";
import PrenotaAppuntamento from "./PrenotaAppuntamento.vue";
import MasterLayout from "../../../components/MasterLayout.vue";
import { usePkSurvey } from "@/stores/usePkSurvey";
import user from "../../../store/modules/user";

const pkSurvey = usePkSurvey();

const prenotaAppuntamentoVisible = ref(false);
const currentDate = ref(new Date());
const currentMentorAvatar = ref(null);
const currentMentor = ref(null);

const users = pkSurvey.users;

// Generiamo 11 appuntamenti con dati casuali e poi li ordiniamo
const appointments = computed(() => {
  console.log("users", users);
  if (users) {
    return Array.from({ length: 11 }, () => {
      const user = users[Math.floor(Math.random() * users.length)];

      // Data casuale nei prossimi 10 giorni
      const randomDate = new Date(
        Date.now() + Math.floor(Math.random() * 10) * 24 * 60 * 60 * 1000
      );

      // Ora casuale tra le 10:00 AM e le 4:00 PM
      const randomHour = 10 + Math.floor(Math.random() * 7); // 10 AM to 4 PM (10 + [0-6])
      const randomMinutes = Math.floor(Math.random() * 60); // Minuti casuali tra 0 e 59

      randomDate.setHours(randomHour, randomMinutes, 0, 0); // Settiamo l'ora casuale

      const confermato = true;

      return {
        mentor: user,
        avatarMentor: user.image,
        date: randomDate.toISOString(), // ISO string include anche l'orario
        confermato,
      };
    });
  } else {
    return [];
  }
});

// Ordinamento in base alla data (cronologico crescente)
appointments.value.sort((a, b) => new Date(a.date) - new Date(b.date));

const onClickChipAppuntamento = (appuntamento) => {
  console.log("appuntamento", appuntamento);
  prenotaAppuntamentoVisible.value = false;

  currentDate.value = appuntamento.date;
  currentMentorAvatar.value = appuntamento.avatarMentor;
  currentMentor.value = appuntamento.mentor;

  prenotaAppuntamentoVisible.value = true;
};
</script>

<template>
  <master-layout smallTitle="I tuoi incontri per" bigTitle="L'Induction Plan">
    <div class="bg-primary border-radius-client">
      <div class="px-2 py-4">
        <div class="my-4 w-full flex justify-content-center align-items-center">
          <span class="text-14" style="color: var(--text-color-light)">
            Tocca una data per vedere o modificare i dettagli
          </span>
        </div>

        <div class="grid">
          <ChipAppuntamento
            v-for="(appuntamento, index) in appointments"
            :key="index"
            :avatarMentor="appuntamento.avatarMentor"
            :date="appuntamento.date"
            :confermato="appuntamento.confermato"
            @click="onClickChipAppuntamento(appuntamento)"
          />
        </div>

        <div
          v-if="prenotaAppuntamentoVisible"
          class="w-full flex justify-content-center align-items-center mt-4 px-2"
        >
          <PrenotaAppuntamento
            :currentMentorAvatar="currentMentorAvatar"
            :currentDate="currentDate"
            :mentor="currentMentor"
            title="Vuoi riprogrammare un'altra data?"
          />
        </div>
      </div>
    </div>
  </master-layout>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
}
</style>
