import { defineStore } from "pinia";
import { ref, computed } from "vue";
import ApiService from "@/common/service.api";

export const usePkSurvey = defineStore("pkSurvey", () => {
  const _users = ref([
    {
      name: "John Doe",
      image: "https://randomuser.me/api/portraits/men/2.jpg",
      selected: true,
      role: "Admin",
    },
    {
      name: "Jane Doe",
      image: "https://randomuser.me/api/portraits/women/3.jpg",
      selected: true,
      role: "Admin",
    },
    {
      name: "John Smith",
      image: "https://randomuser.me/api/portraits/men/3.jpg",
      selected: true,
      role: "Team Leader",
    },
    {
      name: "Alice Johnson",
      image: "https://randomuser.me/api/portraits/women/5.jpg",
      selected: true,
      role: "Team Leader",
    },
    {
      name: "Bob Brown",
      image: "https://randomuser.me/api/portraits/men/5.jpg",
      selected: false,
      role: "Team Leader",
    },
    {
      name: "Charlie Davis",
      image: "https://randomuser.me/api/portraits/men/2.jpg",
      selected: false,
      role: "Team Leader",
    },
    {
      name: "Diana Evans",
      image: "https://randomuser.me/api/portraits/women/1.jpg",
      selected: false,
      role: "Support",
    },
    {
      name: "Evan Green",
      image: "https://randomuser.me/api/portraits/men/6.jpg",
      selected: false,
      role: "Support",
    },
    {
      name: "Fiona Harris",
      image: "https://randomuser.me/api/portraits/women/6.jpg",
      selected: false,
      role: "Support",
    },
    {
      name: "George White",
      image: "https://randomuser.me/api/portraits/men/9.jpg",
      selected: false,
      role: "Support",
    },
    {
      name: "Hannah Moore",
      image: "https://randomuser.me/api/portraits/women/3.jpg",
      selected: false,
      role: "Support",
    },
    {
      name: "Ian Martin",
      image: "https://randomuser.me/api/portraits/men/7.jpg",
      selected: false,
      role: "Support",
    },
    {
      name: "Jessica Lee",
      image: "https://randomuser.me/api/portraits/women/13.jpg",
      selected: false,
      role: "Seller",
    },
    {
      name: "Kevin Clark",
      image: "https://randomuser.me/api/portraits/men/9.jpg",
      selected: false,
      role: "Seller",
    },
    {
      name: "Lily Walker",
      image: "https://randomuser.me/api/portraits/women/2.jpg",
      selected: false,
      role: "Seller",
    },
    {
      name: "Michael Young",
      image: "https://randomuser.me/api/portraits/men/10.jpg",
      selected: false,
      role: "Seller",
    },
    {
      name: "Natalie King",
      image: "https://randomuser.me/api/portraits/women/10.jpg",
      selected: false,
      role: "Seller",
    },
    {
      name: "Oscar Hill",
      image: "https://randomuser.me/api/portraits/men/11.jpg",
      selected: false,
      role: "Seller",
    },
    {
      name: "Paula Scott",
      image: "https://randomuser.me/api/portraits/women/11.jpg",
      selected: false,
      role: "Seller",
    },
    {
      name: "Quinn Adams",
      image: "https://randomuser.me/api/portraits/women/3.jpg",
      selected: false,
      role: "Seller",
    },
  ]);

  const _currentUser = ref(
    localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : null
  );

  const _currentSurvey = ref(
    localStorage.getItem("currentSurvey")
      ? JSON.parse(localStorage.getItem("currentSurvey"))
      : null
  );

  const users = computed(() => _users.value);

  const currentUser = computed(() => _currentUser.value);

  const currentSurvey = computed(() => _currentSurvey.value);

  const setCurrentUser = (user) => {
    _currentUser.value = user;
    localStorage.setItem("currentUser", JSON.stringify(user));
  };

  const setCurrentSurvey = (survey) => {
    _currentSurvey.value = survey;
    localStorage.setItem("currentSurvey", JSON.stringify(survey));
  };

  return {
    // state
    _users,
    _currentUser,
    _currentSurvey,
    // getters
    users,
    currentUser,
    currentSurvey,
    // setters
    setCurrentUser,
    setCurrentSurvey,
  };
});
